import { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { Nav, Container, Navbar } from "react-bootstrap";

import Logo from "../assets/logo-128.png";

const Menu = () => {

  const location = useLocation();
  const [url, setUrl] = useState(null);
  useEffect(() => { setUrl(location.pathname); }, [location]);

  return (
    <Navbar collapseOnSelect expand="lg" className="dm-navbar navbar-dark" sticky="top">
      <Container>
        <Navbar.Brand href="/"><img alt="" src={Logo} className="d-inline-block align-middle my-0 me-2" width="54" />Marmottoshis</Navbar.Brand>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className="my-2">
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="/" href="/" className={(url === "/" ? "active" : "inactive")}>Home</Nav.Link>
            <Nav.Link as={Link} to="/dashboard" href="/dashboard" className={(url === "/dashboard" ? "active" : "inactive")}>Dashboard</Nav.Link>
            <Nav.Link as={Link} to="/burn" href="/burn" className={(url === "/burn" ? "active" : "inactive")}>Burn & Redeem</Nav.Link>
            <Nav.Link as={Link} to="/adopt" href="/adopt" className={(url === "/adopt" ? "active" : "inactive")}>Adopt A Marmot</Nav.Link>
            {/*<Nav.Link as={Link} to="/marmottoshow" href="/marmottoshow" className={(url && url.includes("/marmottoshow") ? "active" : "inactive")}>Marmotto Show</Nav.Link>*/}
            <Nav.Link as={Link} to="/trocador" href="/trocador" className={(url === "/trocador" ? "active" : "inactive")}>Swap</Nav.Link>
          </Nav>
        </Navbar.Collapse>

      </Container>
    </Navbar>
  );
};

export default Menu;