import { useEffect, useState } from 'react';
import { Container, Row, Col, Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faMedium } from '@fortawesome/free-brands-svg-icons'

import Logo from "../assets/logo-128.png";
import LitePaper from "../assets/LitePaper.pdf";

<FontAwesomeIcon icon="fa-brands fa-twitter" />

const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
const contractChainExplorer = process.env.REACT_APP_CONTRACT_CHAIN_EXPLORER;
const adoptContractAddress = process.env.REACT_APP_ADOPT_CONTRACT_ADDRESS;
const adoptContractChainExplorer = process.env.REACT_APP_ADOPT_CONTRACT_CHAIN_EXPLORER;
const marmottoShowContractAddress = process.env.REACT_APP_MARMOTTOSHOW_CONTRACT_ADDRESS;
const marmottoShowContractChainExplorer = process.env.REACT_APP_MARMOTTOSHOW_CONTRACT_CHAIN_EXPLORER;
const version = process.env.REACT_APP_VERSION;

const Footer = ({ props }) => {

  /*const { isAuthorizedAdminWallet } = props*/
  const year = new Date().getFullYear();

  const location = useLocation();
  const [url, setUrl] = useState(null);
  useEffect(() => { setUrl(location.pathname); }, [location]);

  return (
    <>
      <footer className='text-center text-capitalize'>
        <Container fluid>
          <Row>
            <Col lg={12} className="mb-4">

              <Nav>
                <ul className="nav col-12 mb-4 justify-content-center dm-sn">
                  <li>
                    <a href="https://twitter.com/Marmottoshis/" target="_blank" rel="noreferrer" className="px-2" title="Twitter">
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </li>
                  <li>
                    <a href="https://medium.com/@Marmottoshis" target="_blank" rel="noreferrer" className="px-2" title="Medium">
                      <FontAwesomeIcon icon={faMedium} />
                    </a>
                  </li>
                </ul>
              </Nav>

              <img src={Logo} className="mb-4" />

              <h3>© {year} Marmottoshis</h3>

              <p><small><Link as={Link} to="/team" href="/team">Team</Link> - <a href={LitePaper} target="_blank">Lite Paper</a></small></p>

              <span className="sc"><b>Smarts Contracts</b> (Collection | Adopt-A-Marmot{/*| MarmottoShow*/})<br /><a href={`https://${contractChainExplorer}/address/${contractAddress}`} target="_blank">{contractAddress}</a> | <a href={`https://${adoptContractChainExplorer}/address/${adoptContractAddress}`} target="_blank">{adoptContractAddress}</a>{/* | <a href={`https://${marmottoShowContractChainExplorer}/address/${marmottoShowContractAddress}`} target="_blank">{contractAddress}</a>*/}</span><br />
              <span className="made-with-love">DApp v.{version} made with <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" className="bi bi-heart-fill" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"></path></svg> by <a href="https://profile.rpgmax.fr" target="_blank">RpGmAx</a></span>

            </Col>

          </Row>
        </Container>

      </footer>
    </>
  )
}

export default Footer