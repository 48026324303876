import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Alert, Table } from "react-bootstrap";

import Meta from '../components/Meta';

import toast from 'react-hot-toast';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faCoins } from '@fortawesome/free-solid-svg-icons';

import { useDebounceValue } from 'usehooks-ts';

import { ConnectButton } from '@rainbow-me/rainbowkit';

import { useReadContract, useSimulateContract, useWriteContract, useReadContracts, useWaitForTransactionReceipt } from 'wagmi';
import { useAccount, useBlockNumber } from 'wagmi';
import { formatEther, parseEther } from 'viem';

import abi from '../contracts/Adopt-ABI.json';

import White from "../assets/adopt/white.png";
import { arbitrum } from 'viem/chains';

const plural = (count) => count >= 2 ? 's' : '';

const adoptSmartContract = {
    address: process.env.REACT_APP_ADOPT_CONTRACT_ADDRESS,
    abi: abi,
    chainId: arbitrum.id
}

/* Prévoir param pour version long/short */
export const formatDate = (timestamp, isShort = false) => {
    const date = new Date(timestamp * 1000);
    const day = "0" + date.getDate();
    const month = "0" + (date.getMonth() + 1);
    const year = date.getFullYear();
    const hours = "0" + date.getHours();
    const minutes = "0" + date.getMinutes();

    if (isShort) {
        return `${day.substr(-2)}/${month.substr(-2)}/${year}`;
    } else {
        return `${day.substr(-2)}/${month.substr(-2)}/${year} à ${hours.substr(-2)}h${minutes.substr(-2)}`;
    }
};


const Adopt = () => {
    const [adoptNumber, setAdoptNumber] = useState(1);
    const pageTitle = 'Marmottoshis - Adopt-A-Marmot';

    const [amount, setAmount] = useState(1);
    const [debouncedAmount, setDebouncedAmount] = useDebounceValue(amount, 250);

    const [allowedDonations, setAllowedDonations] = useState();
    const [maxParents, setMaxParents] = useState();

    const [currentAdoptionId, setCurrentAdoptionId] = useState();
    const [adoptionInfos, setAdoptionInfos] = useState([]);
    const [donationAmount, setDonationAmount] = useState();

    const [allAdoptionInfos, setAllAdoptionInfos] = useState([]);

    const [allowedDonationsMinusUsed, setAllowedDonationsMinusUsed] = useState(0);

    const { address, isConnected } = useAccount()
    const { chain } = useAccount()

    // Récupération des infos nécessaires
    const { data: multipleOnChainReads } = useReadContracts({
        contracts: [
            {
                ...adoptSmartContract,
                functionName: 'getCurrentAdoptionId',
            },
            {
                ...adoptSmartContract,
                functionName: 'maxDonationsPerWallet',
            },
            {
                ...adoptSmartContract,
                functionName: 'maxParents',
            },
            {
                ...adoptSmartContract,
                functionName: 'donationAmount',
            },
            {
                ...adoptSmartContract,
                functionName: 'getAllAdoptionsInfos',
                args: [true]
            }
        ]
    })

    useEffect(() => {
        if (multipleOnChainReads) {
            setCurrentAdoptionId(parseInt(multipleOnChainReads[0].result));
            setAllowedDonations(parseInt(multipleOnChainReads[1].result));
            setMaxParents(parseInt(multipleOnChainReads[2].result));
            setDonationAmount(formatEther(multipleOnChainReads[3].result));
            setAllAdoptionInfos(multipleOnChainReads[4].result);
        }

    }, [multipleOnChainReads]);


    const { data: onChainAdoptionInfos, refetch } = useReadContract({ ...adoptSmartContract, account: address, functionName: 'getAdoptionInfos', args: [currentAdoptionId], query: { enabled: Boolean(address && currentAdoptionId >= 0) } })
    useEffect(() => {
        if (onChainAdoptionInfos) {
            setAdoptionInfos(onChainAdoptionInfos);
        }

    }, [onChainAdoptionInfos]);

    // Nouvelle méthod pour refresh !
    //const { data: blockNumber } = useBlockNumber({ watch: true })
    /*useEffect(() => {
        refetch()
    }, [blockNumber])*/

    // Set max mint - used mint
    useEffect(() => {
        if (adoptionInfos && adoptionInfos.callerDonations >= 0) {
            setAllowedDonationsMinusUsed(allowedDonations - parseInt(adoptionInfos.callerDonations));
        }
    }, [allowedDonations, adoptionInfos]);

    // Emotion ('cause it's cool) !
    useEffect(() => {
        if (allowedDonations > 0 && adoptionInfos && adoptionInfos.callerDonations >= 0) {
            const totalDonations = amount + parseInt(adoptionInfos.callerDonations) - 1;
            const emotionStep = allowedDonations / 4;
            const adoptNumber = Math.min(Math.floor(totalDonations / emotionStep) + 1, 4);
            setAdoptNumber(adoptNumber);
        }
    }, [amount, adoptionInfos, allowedDonations]);

    // Préparation de la donation avec quantité souhaitée
    const {
        data: donationData,
        error: donationPrepareError,
        isError: donationIsPrepareError,
        isSuccess: donationIsPrepareSuccess,
        refetch: donationRefetch,
    } = useSimulateContract({
        address: process.env.REACT_APP_ADOPT_CONTRACT_ADDRESS,
        abi: abi,
        chainId: arbitrum.id,
        functionName: 'adoptRequestDonation',
        args: [debouncedAmount],
        value: donationAmount >= 0 && parseEther((donationAmount * debouncedAmount).toString()),
        query: { enabled: Boolean(address && currentAdoptionId >= 0 && donationAmount), retry: false }
    });

    const { data: hash, writeContract: donationWrite, error } = useWriteContract();

    const { isLoading: donationIsLoading, isSuccess: donationIsSuccess } = useWaitForTransactionReceipt({
        hash
    });


    useEffect(() => { if (donationIsSuccess) { donationRefetch(); refetch(); setAmount(1); } }, [donationIsSuccess]); // Post donation on refait une simulation de donation

    // Incrémentation/Réduction de la quantité à mint
    const increaseAmount = () => { if (amount < allowedDonationsMinusUsed) { setAmount(prevAmount => prevAmount + 1); } } // Incrémentation mais avec limite

    const decreaseAmount = () => { if (amount > 1) { setAmount(prevAmount => prevAmount - 1); } }// Réduction sans pouvoir aller à zéro

    // Permet d'avoir maxi 3 chiffres après la virgule si nécessaire, sinon 2 et en dernier recours l'entier uniquement
    function formatNumber(num) {
        let formatted = num.toFixed(3);
        if (formatted.endsWith('0')) {
            formatted = formatted.slice(0, -1);
        }
        return formatted.endsWith('.00') ? formatted.slice(0, -3) : formatted;
    }

    // Donation effectuée
    useEffect(() => {
        if (donationIsSuccess) {
            toast.dismiss();
            toast.success(() => (<div><small>Donation réussie !</small></div>), { style: { wordBreak: 'break-word' } });
        }
    }, [donationIsSuccess])

    // Messages d'erreur plus lisibles
    const friendlyErrorMessage = (errorMsg) => {
        const errorMappings = {
            "Limit reached": "Nombre maximal de donations / wallet atteint",
            "Adoption system is paused": "L'adoption est actuellement en pause"
        };

        const errorMessage = errorMappings[errorMsg];

        if (typeof errorMessage === 'function') { return errorMessage(); }
        return errorMessage || 'Il semblerait que vous soyez à court d\'ETH dans votre wallet... N\'hésitez pas à consulter la page Swap pour bridger vers Arbitrum.'; // Message d'erreur par défaut
    };

    return (
        <>
            <Meta title={pageTitle} />
            <Container className="mt-4">
                <Row className="mb-4">
                    <Col lg={12}>
                        <h1 className="text-center my-4 page-title">Adopt A Marmot</h1>
                        <div className="connect-zone my-2"><ConnectButton chainStatus="icon" label="Connecter son wallet" /></div>
                    </Col>
                </Row>

                {isConnected && (<>

                    {chain && chain.id != arbitrum.id ? (<p className="text-center mb-5">- L'adoption se passe sur Arbitrum, veuillez changer de blockchain -</p>) : (<>
                        <Row className="text-center justify-content-md-center">
                            <Col lg={6} md={12} className="mb-5 py-2 relief">

                                {adoptionInfos && parseInt(adoptionInfos.endDate) == 0 ? (<>
                                    <img src={White} width={300} className={`img-fluid my-3 member-pfp shadow-lg adopt-marmot adopt-marmot-${adoptNumber}`} />

                                    <div className="card relief ">
                                        <div className="card-header">Adoption en cours <small>(débutée le <span className="info" title={formatDate(Number(adoptionInfos.startDate), false)}>{formatDate(Number(adoptionInfos.startDate), true)})</span></small></div>
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">{formatNumber(Number(donationAmount))} ETH par donation ~ {allowedDonations} donations / wallet ~ {maxParents} parent{plural(maxParents)} recherché{plural(maxParents)}</li>
                                            <li className="list-group-item">Actuellement {parseInt(adoptionInfos.donations)} donation{plural(parseInt(adoptionInfos.donations))}</li>
                                            <li className="list-group-item">Vous avez réalisé {parseInt(adoptionInfos.callerDonations)} / {allowedDonations} donation{plural(allowedDonations)} possible{plural(allowedDonations)}<br />
                                                {Array.from({ length: allowedDonations }, (_, i) => (
                                                    <span key={i} className="mt-2 mx-1">{i < parseInt(adoptionInfos.callerDonations) ? '❤️' : '🩶'}</span>
                                                ))}
                                            </li>
                                        </ul>
                                    </div>

                                    {allowedDonationsMinusUsed >= 1 && (<>

                                        <Row className="justify-content-md-center my-4">
                                            <Col lg={5}>
                                                <div className="input-group">
                                                    <div className="input-group-text">Donations</div>
                                                    <button className="btn btn-md btn-light btn-qty" onClick={decreaseAmount} disabled={amount === 1}>
                                                        <FontAwesomeIcon icon={faMinus} />
                                                    </button>
                                                    <input className="form-control form-control-sm" type="text" value={amount} readOnly />
                                                    <button variant="light" className="btn btn-sm btn-light btn-qty" onClick={increaseAmount} disabled={amount >= allowedDonationsMinusUsed}>
                                                        <FontAwesomeIcon icon={faPlus} />
                                                    </button>
                                                </div>
                                            </Col>
                                            <Col lg={6} className="mt-4 mt-sm-0">
                                                <button className={`btn ${donationIsPrepareError ? 'btn-danger' : 'btn-light'}`} disabled={donationIsLoading || !donationIsPrepareSuccess} onClick={() => donationWrite(donationData?.request)}>
                                                    <FontAwesomeIcon icon={faCoins} className="px-2" />{!donationIsLoading ? (<>Faire un don ({formatNumber(Number(donationAmount * amount))} ETH)</>) : (<>Donation en cours...</>)}
                                                </button>
                                            </Col>
                                        </Row>

                                    </>)}

                                    {donationIsPrepareError && (<>
                                        <div className="single-item-content mt-4 fs-6">
                                            <Alert variant='light'><span className="text-danger">{friendlyErrorMessage(donationPrepareError.cause.reason)}</span></Alert>
                                        </div></>)}

                                </>) : (<>
                                    <img src={White} width={300} className={`img-fluid my-3 member-pfp shadow-lg adopt-marmot no-adoption`} />
                                    <h5 className="mb-0">Désolé, il n'y a aucune adoption en ce moment...</h5>
                                </>)}

                            </Col>
                        </Row>

                        <Row>
                            <Col sm={12} className="relief mb-5 p-4">

                                <h4 className="mb-4">Résultats des précédentes adoptions</h4>

                                {allAdoptionInfos && allAdoptionInfos.length >= 1 ? (<>
                                    <Table responsive className="text-white small">
                                        <thead>
                                            <tr>
                                                <th width="25%">Date de début</th>
                                                <th width="25%">Date de fin</th>
                                                <th width="50%">Nouveaux parents</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {[...allAdoptionInfos].reverse().map((adoption, index) => (
                                                <tr key={index} className="text-white">
                                                    <td><span className="info" title={formatDate(Number(adoption.startDate), false)}>{formatDate(Number(adoption.startDate), true)}</span></td>
                                                    <td><span className="info" title={formatDate(Number(adoption.endDate), false)}>{formatDate(Number(adoption.endDate), true)}</span></td>
                                                    <td>
                                                        {adoption.newParents.map((parent, index) => (
                                                            <span key={index}>{parent}{index < adoption.newParents.length - 1 ? ', ' : ''}</span>
                                                        ))}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </>) : (<>Patience, il faut déjà qu'une adoption se termine...</>)}


                            </Col>

                        </Row>

                    </>)}

                </>)}
            </Container>
        </>
    )
}

export default Adopt;
